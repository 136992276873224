import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Button } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import HSpacing from 'bloko/blocks/hSpacing';
import {
    InfoScaleSmall,
    CheckmarkScaleSmallKindSingleAppearanceOutlinedEnclosedTrue,
    ThumbScaleSmallKindUpReducedFalse,
    IconColor,
} from 'bloko/blocks/icon';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import { updateUrl } from 'Modules/url';
import VacancyResponseLink from 'src/components/VacancyResponseLink';
import VacancyResponsePopup from 'src/components/VacancyResponsePopup';
import CountriesProfileVisibilityAgreementPopup from 'src/components/VacancyResponsePopup/CountriesProfileVisibilityAgreementPopup';
import RelocationWarningPopup from 'src/components/VacancyResponsePopup/RelocationWarningPopup';
import ChatikButton from 'src/components/VacancySearchItem/ChatikButton';
import { useCardContainerClassNames } from 'src/components/VacancySearchItem/hooks/useCardContainerClassNames';
import { useWorkflowState } from 'src/components/VacancySearchItem/hooks/useWorkflowState';
import Source from 'src/components/VacancySearchItem/types/Source';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { incrementApplicantInvitationsCount } from 'src/models/userStats';

import styles from './styles.less';

const ResponseButton = ({
    vacancyId,
    employerId,
    vrImmediateRedirectUrl,
    trls,
    vacancySource,
    clickUrl,
    vacancyOfTheDayClickUrl,
    isAdv,
    isResponseButtonVisible,
    isNoPopups,
    isStretched,
}) => {
    const isXs = useBreakpoint() === Breakpoint.XS;
    const responseStatus = useSelector((state) => state.applicantVacancyResponseStatuses[vacancyId]);
    const dispatch = useDispatch();
    const { wideCardContainerClassName } = useCardContainerClassNames();
    const { isResponded, isInvited, isDiscarded, showWorkflowStatus } = useWorkflowState(vacancyId);

    if (!isResponseButtonVisible) {
        return null;
    }

    const respondedState = (text, status) => {
        return (
            <div className={styles.workflowStatusContainer} data-qa={`vacancy-serp__vacancy_${status}`}>
                {status === 'responded' && <ThumbScaleSmallKindUpReducedFalse initial={IconColor.Gray50} />}
                {status === 'invited' && (
                    <CheckmarkScaleSmallKindSingleAppearanceOutlinedEnclosedTrue initial={IconColor.Green60} />
                )}
                {status === 'discard' && <InfoScaleSmall initial={IconColor.Orange60} />}
                <HSpacing base={2} />
                <span
                    className={classnames(styles.workflowStatusText, {
                        [styles.workflowStatusTextResponded]: isResponded,
                        [styles.workflowStatusTextInvited]: isInvited,
                        [styles.workflowStatusTextDiscarded]: isDiscarded,
                    })}
                >
                    {text}
                </span>
            </div>
        );
    };

    let workflowState;

    if (isResponded) {
        workflowState = respondedState(trls[ResponseButton.trls.responded], 'responded');
    } else if (isInvited) {
        workflowState = respondedState(trls[ResponseButton.trls.youWereInvited], 'invited');
    } else if (isDiscarded) {
        workflowState = respondedState(trls[ResponseButton.trls.discard], 'discard');
    }

    let toUrl = `/applicant/vacancy_response?vacancyId=${vacancyId}${employerId ? `&employerId=${employerId}` : ''}`;
    if (vrImmediateRedirectUrl) {
        toUrl = updateUrl(vrImmediateRedirectUrl, { hhtmFromLabel: 'vacancy_immediate_redirect' });
    }

    const adVacancyClickUrl = isAdv && clickUrl ? updateUrl(clickUrl, { clickType: 'response_button' }) : null;

    if (showWorkflowStatus) {
        return (
            <div className={classnames(styles.workflowStatusRedesign)}>
                {workflowState}
                <HSpacing base={3} />

                <div className={wideCardContainerClassName}>
                    <ChatikButton vacancyId={vacancyId} vacancySource={vacancySource}>
                        {trls[ResponseButton.trls.chat]}
                    </ChatikButton>
                </div>
            </div>
        );
    }

    return (
        <>
            {!showWorkflowStatus && (
                <VacancyResponseLink
                    vacancyId={vacancyId}
                    employerId={employerId}
                    enableRelocationWarning
                    vrImmediateRedirectUrl={vrImmediateRedirectUrl}
                    vacancySource={vacancySource}
                    adVacancyClickUrl={adVacancyClickUrl}
                    vacancyOfTheDayClickUrl={vacancyOfTheDayClickUrl}
                    place="vacancy_search_item"
                    onResponseComplete={() => {
                        dispatch(incrementApplicantInvitationsCount(1));
                    }}
                    render={(onClick) => {
                        return (
                            <Button
                                Element={Link}
                                to={toUrl}
                                target={vrImmediateRedirectUrl ? '_blank' : null}
                                onClick={onClick}
                                loading={responseStatus?.isFetching && !responseStatus?.isFetchingWithQuestionResponse}
                                disabled={Boolean(responseStatus?.isFetching)}
                                data-qa="vacancy-serp__vacancy_response"
                                stretched={isStretched}
                                style="accent"
                                mode="primary"
                            >
                                {trls[ResponseButton.trls.response]}
                            </Button>
                        );
                    }}
                />
            )}
            {!isNoPopups && (
                <>
                    <RelocationWarningPopup vacancyId={vacancyId} />
                    <CountriesProfileVisibilityAgreementPopup vacancyId={vacancyId} />
                    <VacancyResponsePopup vacancyId={vacancyId} vacancySource={vacancySource} isXs={isXs} />
                </>
            )}
        </>
    );
};

ResponseButton.trls = {
    response: 'search.result.item.response',
    responded: 'vacancySearchResults.applicantflags.responded',
    youWereInvited: 'vacancySearchResults.applicantflags.youWereInvited',
    discard: 'vacancySearchResults.applicantflags.rejected',
    chat: 'vacancySearchResults.chat',
    toChat: 'vacancySearchResults.toChat',
};

ResponseButton.propTypes = {
    vacancyId: PropTypes.number,
    employerId: PropTypes.number,
    vrImmediateRedirectUrl: PropTypes.string,
    trls: PropTypes.object,
    vacancySource: PropTypes.oneOf(Object.values(Source)),
    clickUrl: PropTypes.string,
    vacancyOfTheDayClickUrl: PropTypes.string,
    isAdv: PropTypes.bool,
    isNoPopups: PropTypes.bool,
    isResponseButtonVisible: PropTypes.bool,
    isStretched: PropTypes.bool,
};

export default translation(ResponseButton);
