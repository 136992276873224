import { FC } from 'react';

import styles from './styles.less';

type Props = {
    isEmployerOnline: boolean;
};

const VacancyIsEmployerOnline: FC<Props> = ({ isEmployerOnline }) => {
    if (!isEmployerOnline) {
        return null;
    }

    return <span className={styles.employerOnlineMagritte} data-qa="vacancy-serp-item-activity" />;
};

export default VacancyIsEmployerOnline;
