import { FC } from 'react';
import classnames from 'classnames';

import Button, { ButtonScale, ButtonKind, ButtonAppearance } from 'bloko/blocks/button';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';

import useToggleState from 'src/hooks/useToggleState';

interface Props {
    score: number;
    explanation: string;
}

const SearchDebug: FC<Props> = ({ score, explanation }) => {
    const [visible, toggleVisible] = useToggleState(false);
    if (score === null) {
        return null;
    }
    if (!explanation) {
        return (
            <>
                <VSpacing base={4} />
                <div>{`score = ${score}`}</div>
            </>
        );
    }
    return (
        <div>
            <VSpacing base={4} />
            <Link
                appearance={LinkAppearance.Pseudo}
                onClick={toggleVisible}
                data-qa="search-debug-link"
            >{`score = ${score}`}</Link>
            <div className={classnames({ 'g-hidden': !visible })}>
                <VSpacing base={4} />
                <Button
                    scale={ButtonScale.Small}
                    kind={ButtonKind.Primary}
                    appearance={ButtonAppearance.Outlined}
                    onClick={() => {
                        void navigator.clipboard.writeText(explanation);
                    }}
                >
                    Copy
                </Button>
                <VSpacing base={2} />
                <pre className="search-result-debug" data-qa="search-result-debug">
                    {explanation}
                </pre>
            </div>
        </div>
    );
};

export default SearchDebug;
