import { Text, HSpacing, VSpacing } from '@hh.ru/magritte-ui';
import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { useCardContainerClassNames } from 'src/components/VacancySearchItem/hooks/useCardContainerClassNames';
import translation from 'src/components/translation';

type VacancyTopRowInfoProps = {
    onlineUsersCount?: number;
    shouldShowAdv?: boolean;
};

const TrlKeys = {
    watching: {
        one: 'vacancySearch.card.views.one',
        some: 'vacancySearch.card.views.some',
        many: 'vacancySearch.card.views.many',
    },
    advMark: 'vacancyItem.advMark',
    clickme: 'vacancy.serp.clickMe',
};

const VacancyTopRowInfo: TranslatedComponent<VacancyTopRowInfoProps> = ({ onlineUsersCount, shouldShowAdv, trls }) => {
    const { narrowCardContainerClassName } = useCardContainerClassNames();
    const showUsersCount = Boolean(onlineUsersCount && onlineUsersCount > 0);
    const showClickMe = Boolean(shouldShowAdv);

    if (!showClickMe && !showUsersCount) {
        return null;
    }

    return (
        <>
            {showClickMe && (
                <>
                    <Text typography="label-4-regular" style="tertiary" Element="span">
                        {trls[TrlKeys.clickme]}
                    </Text>
                    <HSpacing default={12} />
                    <span className={narrowCardContainerClassName}>
                        <VSpacing default={8} />
                    </span>
                </>
            )}

            {showUsersCount && (
                <Text typography="label-4-regular" style="tertiary" Element="span">
                    <ConversionNumber
                        value={onlineUsersCount as number}
                        one={trls[TrlKeys.watching.one]}
                        some={trls[TrlKeys.watching.some]}
                        many={trls[TrlKeys.watching.many]}
                        hasValue={false}
                        format={(trl) =>
                            format(trl, {
                                '{0}': onlineUsersCount as number,
                            })
                        }
                    />
                </Text>
            )}
            <VSpacing default={8} />
        </>
    );
};

export default translation(VacancyTopRowInfo);
