import {
    Title,
    Cell,
    Avatar,
    CellText,
    Card,
    VSpacingContainer,
    Banner,
    Text,
    useBreakpoint,
    VSpacing,
} from '@hh.ru/magritte-ui';
import { PhoneOutlinedSize24, EnvelopeOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Link as ReduxLink, usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { VacancyChatInfoType } from 'src/models/vacancyChatInfo';
import { ContactInfo } from 'src/models/vacancyView.types';

import ContactChat from 'src/components/VacancyView/ContactChat';

interface Props {
    vacancyId: number;
    contactInfo?: ContactInfo | null;
    isCallTrackingEnabled: boolean;
    close: () => void;
    onPhoneClick?: () => void;
    onEmailClick?: () => void;
    chatButtonInfo?: VacancyChatInfoType | null;
    employerId?: number;
    isChatEnabled?: boolean;
    isQuestionExp?: boolean;
}

const TrlKeys = {
    textC: 'vacancy.contacts.callTracking.disclaimer.text.c',
    phone: 'vacancy.contacts.phone',
    email: 'vacancy.contacts.email',
};

const VacancyContactsMagritte: TranslatedComponent<Props> = ({
    vacancyId,
    contactInfo,
    onPhoneClick,
    onEmailClick,
    close,
    isCallTrackingEnabled,
    isChatEnabled,
    chatButtonInfo,
    employerId,
    trls,
    isQuestionExp,
}) => {
    const { isMobile } = useBreakpoint();
    const push = usePush();

    const { fio, email } = contactInfo || {};
    const phones = contactInfo?.phones?.phones;

    const handlePhoneClick = (phone: string) => {
        push(`tel:+${phone}`);
        onPhoneClick?.();
    };

    const handleEmailClick = (email: string) => {
        push(`mailto:${email}`);
        onEmailClick?.();
    };

    if (isQuestionExp && isMobile) {
        return (
            <VSpacingContainer default={12}>
                {!!phones?.length && isCallTrackingEnabled && (
                    <Banner
                        style="neutral"
                        showClose={false}
                        content={<Text typography="paragraph-2-regular">{trls[TrlKeys.textC]}</Text>}
                    />
                )}
                {phones?.map((phone) => {
                    const { country, city, number, comment } = phone;
                    const formattedPhone = `+${country}\u00a0${city}\u00a0${number}`;
                    return (
                        <Card
                            key={number}
                            padding={16}
                            borderRadius={24}
                            showBorder
                            onClick={() => handlePhoneClick(`${country}${city}${number}`)}
                        >
                            <Cell right={<PhoneOutlinedSize24 initial="secondary" />}>
                                <CellText type="subtitle">{trls[TrlKeys.phone]}</CellText>
                                <CellText type="title">{formattedPhone}</CellText>
                            </Cell>
                            <VSpacing default={8} />
                            <CellText type="subtitle">{comment}</CellText>
                        </Card>
                    );
                })}
                {email && (
                    <Card padding={16} borderRadius={24} showBorder onClick={() => handleEmailClick(email)}>
                        <Cell right={<EnvelopeOutlinedSize24 initial="secondary" />}>
                            <CellText type="title">{trls[TrlKeys.email]}</CellText>
                            <CellText type="subtitle">{email}</CellText>
                        </Cell>
                    </Card>
                )}
            </VSpacingContainer>
        );
    }

    return (
        <VSpacingContainer default={24}>
            {fio && (
                <Title size="small" Element="h4">
                    {fio}
                </Title>
            )}
            {chatButtonInfo && isChatEnabled && employerId && (
                <ContactChat
                    chatButtonInfo={chatButtonInfo}
                    vacancyId={vacancyId}
                    employerId={employerId}
                    onClick={close}
                    isMagritteExp
                    isQuestionExp={isQuestionExp}
                />
            )}
            {!!phones?.length && isCallTrackingEnabled && (
                <Card borderRadius={12} padding={16} style="positive">
                    {trls[TrlKeys.textC]}
                </Card>
            )}
            {phones?.map((phone) => {
                const { country, city, number, comment } = phone;
                const formattedPhone = `+${country}\u00a0${city}\u00a0${number}`;
                return (
                    <Cell
                        key={number}
                        avatar={
                            <Avatar
                                aria-label={trls[TrlKeys.phone]}
                                mode="icon"
                                size={48}
                                style="secondary"
                                icon={<PhoneOutlinedSize24 initial="primary" />}
                            />
                        }
                    >
                        <CellText type="subtitle">{trls[TrlKeys.phone]}</CellText>
                        <ReduxLink
                            to={`tel:+${country}${city}${number}`}
                            data-qa="vacancy-serp__vacancy_contacts-phone-link"
                            onClick={onPhoneClick}
                        >
                            <CellText type="title">{formattedPhone}</CellText>
                        </ReduxLink>
                        <CellText type="subtitle">{comment}</CellText>
                    </Cell>
                );
            })}
            {email && (
                <Cell
                    avatar={
                        <Avatar
                            aria-label={trls[TrlKeys.email]}
                            mode="icon"
                            size={48}
                            style="secondary"
                            icon={<EnvelopeOutlinedSize24 initial="primary" />}
                        />
                    }
                >
                    <CellText type="title">{trls[TrlKeys.email]}</CellText>
                    <ReduxLink
                        to={`mailto:${email}`}
                        data-qa="vacancy-serp__vacancy_contacts-email"
                        onClick={onEmailClick}
                    >
                        <CellText type="subtitle">{email}</CellText>
                    </ReduxLink>
                </Cell>
            )}
        </VSpacingContainer>
    );
};

export default translation(VacancyContactsMagritte);
