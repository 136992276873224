import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import HSpasing from 'bloko/blocks/hSpacing';

import CdnImg from 'src/components/CdnImg';
import translation from 'src/components/translation';
import { ResponseStatusResume } from 'src/models/applicantVacancyResponseStatuses';
import { Gender } from 'src/models/resume/resumeCommon.types';

import styles from './resume-with-photo.less';

const ResumeWithPhoto: FC<{ resume: ResponseStatusResume } & PropsWithChildren> = ({ resume, children }) => {
    const photo = resume.photoUrls?.[0]?.preview;
    const gender = resume.gender?.[0].string;
    return (
        <div className={styles.resumeInfo}>
            {photo ? (
                <CdnImg className={styles.photo} loading="lazy" path={photo} alt="" />
            ) : (
                <div className={classnames(styles.photo, styles.photoEmpty)}>
                    <div
                        className={classnames(styles.avatar, {
                            [styles.avatarMale]: gender === Gender.Male,
                            [styles.avatarFemale]: gender === Gender.Female,
                        })}
                    />
                </div>
            )}
            <HSpasing base={4} />
            <div>{children}</div>
        </div>
    );
};

export default translation(ResumeWithPhoto);
