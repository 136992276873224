import Analytics from '@hh.ru/analytics-js';
import { ChevronScaleMediumKindRight, IconColor } from 'bloko/blocks/icon';
import { ModalHeader } from 'bloko/blocks/modal';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { ResponseStatusResume } from 'src/models/applicantVacancyResponseStatuses';

import ResumeWithPhoto from 'src/components/VacancyResponsePopup/BottomSheet/ResumeWithPhoto';

import styles from './header.less';

const TrlKeys = {
    resumes: 'vacancy.response.popup.resumes',
    questionResumes: 'vacancy.questionResponse.popup.title',
};

interface HeaderProps {
    resume: ResponseStatusResume;
    onClick: () => void;
    allowClick: boolean;
    vacancyId: number;
    isQuestionResponse?: boolean;
}

const Header: TranslatedComponent<HeaderProps> = ({
    resume,
    onClick,
    allowClick,
    vacancyId,
    isQuestionResponse,
    trls,
}) => {
    if (!resume) {
        return null;
    }
    const handleClick = () => {
        if (!allowClick) {
            return;
        }
        Analytics.sendHHEventButtonClick('response_resume_selector_open', {
            resumeHash: resume._attributes.hash,
            vacancyId,
        });
        onClick();
    };
    return (
        <ModalHeader outlined>
            <VSpacing base={1} />
            <div className={styles.header} onClick={handleClick}>
                <ResumeWithPhoto resume={resume}>
                    <Text strong>{trls[isQuestionResponse ? TrlKeys.questionResumes : TrlKeys.resumes]}</Text>
                    <VSpacing base={1} />
                    {resume.title?.[0]?.string}
                </ResumeWithPhoto>
                {allowClick && <ChevronScaleMediumKindRight initial={IconColor.Gray50} />}
            </div>
        </ModalHeader>
    );
};

export default translation(Header);
