import { useMemo } from 'react';

import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import { isString } from 'Utils/Utils';
import {
    BrandedSnippetDecoration,
    BrandSnippet,
    PictureType,
} from 'src/components/BrandedSnippet/brandedSnippet.types';
import getGradient from 'src/components/BrandedSnippet/utils/getGradient';
import getPictureAttributes from 'src/components/BrandedSnippet/utils/getPictureAttributes';
import { useSelector } from 'src/hooks/useSelector';

import useImagesLoader from 'src/components/BrandedSnippet/hooks/useImagesLoader';

const useBrandedSnippetDecoration = ({
    branding,
    isBrandedSnippetAllowed,
}: {
    branding?: BrandSnippet;
    isBrandedSnippetAllowed: boolean;
}): BrandedSnippetDecoration => {
    const isXS = useBreakpoint() === Breakpoint.XS;
    const isMapVacancySearch = useSelector((state) => state.isMapVacancySearch);
    const showViewForXs = isXS || isMapVacancySearch;
    const logo = branding ? getPictureAttributes(branding, showViewForXs, PictureType.Logo) : undefined;
    const picture = branding ? getPictureAttributes(branding, showViewForXs, PictureType.Picture) : undefined;
    const logoSrc = logo?.src;
    const pictureSrc = picture?.src;
    const pictureSources = useMemo(() => [logoSrc, pictureSrc].filter(isString), [logoSrc, pictureSrc]);
    const { isLoading, hasLoadingError } = useImagesLoader(pictureSources, isBrandedSnippetAllowed);
    const hasAdditionalPicture = !!picture?.src;
    const background = branding?.background;
    const backgroundStyle = isLoading
        ? {}
        : { backgroundImage: getGradient(background?.gradient), backgroundColor: background?.color };

    return {
        hasLoadingError,
        isLoading,
        hasAdditionalPicture,
        logo,
        picture,
        showViewForXs,
        backgroundStyle,
    };
};

export default useBrandedSnippetDecoration;
