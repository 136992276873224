import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import CompensationFormattedText from 'src/components/Compensation';
import translation from 'src/components/translation';
import VacancyCompenstionType from 'src/models/vacancy/compensation.type';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

interface CompensationProps {
    compensation: VacancyCompenstionType;
}

const TrlKeys = {
    net: 'employer.newVacancy.compensation.net',
    gross: 'employer.newVacancy.compensation.gross',
};

const Compensation: TranslatedComponent<CompensationProps> = ({ compensation, trls }) => {
    if (!compensation.from && !compensation.to) {
        return null;
    }

    const compensationElement = (
        <>
            <CompensationFormattedText
                from={compensation.from}
                to={compensation.to}
                currencyCode={compensation.currencyCode}
                dash
            />
            {NON_BREAKING_SPACE}
            {trls[compensation.gross ? TrlKeys.gross : TrlKeys.net]}
        </>
    );

    return (
        <Text Element="span" typography="label-1-regular">
            {compensationElement}
        </Text>
    );
};

export default translation(Compensation);
