import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import vacancyResponseAlertPrimaryButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/response/vacancy_response_alert_primary_button_click';
import vacancyResponseAlertScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/response/vacancy_response_alert_screen_shown';
import vacancyResponseAlertSecondaryButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/response/vacancy_response_alert_secondary_button_click';
import { Alert, Button as MagritteButton } from '@hh.ru/magritte-ui';
import { ExclamationCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import Button, { ButtonKind, ButtonScale } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Modal, { ModalTitle, ModalHeader, ModalContent, ModalFooter } from 'bloko/blocks/modal';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';

const relocationWarningAction = makeSetStoreField('relocationWarning');

const TrlKeys = {
    title: 'vacancy.respond.popup.title',
    message: 'vacancy.respond.popup.message',
    country: 'vacancy.respond.popup.country',
    notice: 'vacancy.respond.popup.notice',
    confirm: 'vacancy.respond.popup.force',
    abort: 'vacancy.respond.popup.not',
    cancel: 'vacancy.respond.popup.cancel',
};

interface RelocationWarningPopupProps {
    vacancyId: number;
}

const ALERT_NAME = 'vacancy_response_foreign_employer';

const RelocationWarningPopup: TranslatedComponent<RelocationWarningPopupProps> = ({ vacancyId, trls }) => {
    const dispatch = useDispatch();
    const relocationWarning = useSelector((state) => state.relocationWarning);
    const relocationWarningVacancyId = relocationWarning?.vacancyId ?? 0;
    const relocationWarningShow = Boolean(relocationWarning?.show);

    const isMagritte = useExperiment('magritte_on_vacancy_response');

    useEffect(() => {
        const isAlertShown = relocationWarningShow && relocationWarningVacancyId === vacancyId;
        if (isAlertShown) {
            vacancyResponseAlertScreenShown({
                vacancyId: `${vacancyId}`,
                alertName: ALERT_NAME,
                hhtmSource: ALERT_NAME,
            });
        }
    }, [relocationWarningVacancyId, relocationWarningShow, vacancyId]);

    if (relocationWarningVacancyId !== vacancyId) {
        return null;
    }

    const confirm = () => {
        vacancyResponseAlertPrimaryButtonClick({
            buttonText: trls[TrlKeys.confirm],
            vacancyId: `${vacancyId}`,
            alertName: ALERT_NAME,
            hhtmSource: ALERT_NAME,
        });

        dispatch(relocationWarningAction({ ...relocationWarning, confirmed: true, show: false }));
    };

    const dismiss = () => dispatch(relocationWarningAction({ ...relocationWarning, confirmed: false, show: false }));

    const dismissByButton = () => {
        vacancyResponseAlertSecondaryButtonClick({
            buttonText: trls[TrlKeys.cancel],
            vacancyId: `${vacancyId}`,
            alertName: ALERT_NAME,
            hhtmSource: ALERT_NAME,
        });

        dismiss();
    };

    if (isMagritte) {
        return (
            <Alert
                visible={relocationWarningShow}
                title={trls[TrlKeys.title]}
                description={trls[TrlKeys.message]}
                icon={<ExclamationCircleFilledSize24 />}
                buttons={
                    <>
                        <MagritteButton
                            mode="primary"
                            style="accent"
                            data-qa="relocation-warning-confirm"
                            onClick={confirm}
                        >
                            {trls[TrlKeys.confirm]}
                        </MagritteButton>
                        <MagritteButton
                            mode="secondary"
                            style="accent"
                            data-qa="relocation-warning-abort"
                            onClick={dismissByButton}
                        >
                            {trls[TrlKeys.cancel]}
                        </MagritteButton>
                    </>
                }
                layout="vertical"
                onClose={dismiss}
            />
        );
    }

    const regionTrl = relocationWarning.regionTrl;

    return (
        <Modal visible={relocationWarningShow} onClose={dismiss} useBottomSheet>
            <ModalHeader>
                <ModalTitle data-qa="relocation-warning-title">{trls[TrlKeys.title]}</ModalTitle>
            </ModalHeader>
            <ModalContent>
                <div className="respond-popup__notice respond-popup__notice_spaced" data-qa="relocation-warning-text">
                    <p>
                        {trls[TrlKeys.country]}
                        {regionTrl && `${regionTrl}.`}
                    </p>
                    <p>{trls[TrlKeys.notice]}</p>
                </div>
            </ModalContent>
            <ModalFooter>
                <Button
                    kind={ButtonKind.Success}
                    scale={ButtonScale.Small}
                    data-qa="relocation-warning-confirm"
                    onClick={confirm}
                >
                    {trls[TrlKeys.confirm]}
                </Button>
                <FormSpacer>
                    <Link
                        appearance={LinkAppearance.Pseudo}
                        data-qa="relocation-warning-abort"
                        onClick={dismissByButton}
                    >
                        {trls[TrlKeys.abort]}
                    </Link>
                </FormSpacer>
            </ModalFooter>
        </Modal>
    );
};

export default translation(RelocationWarningPopup);
