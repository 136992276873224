import { VacancyCompany } from 'src/models/vacancySearch/vacancySearchItem.types';

const useGetCompanyUrl = (company: VacancyCompany, advClickUrl?: string): string => {
    let companyUrl = `/employer/${company.id}`;

    if (company.department || advClickUrl) {
        companyUrl += '?';
        if (company.department) {
            companyUrl += `dpt=${company.department?.['@code']}`;
        }
        if (advClickUrl) {
            companyUrl += '&from=vacancySearchAds&hhtmFromLabel=vacancySearchAds';
        }
    }

    return companyUrl;
};

export default useGetCompanyUrl;
