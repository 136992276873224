import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import BlacklistMenu from 'src/components/VacancyView/BlacklistMenu';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

interface BlacklistButtonProps {
    vacancyId: number;
    employerId?: number;
    isAnonymousVacancy: boolean;
}

const BlacklistButton: TranslatedComponent<BlacklistButtonProps> = ({ vacancyId, employerId, isAnonymousVacancy }) => {
    const isFavoritesPage = useSelector(({ router }) =>
        router.location.pathname.startsWith('/applicant/favorite_vacancies')
    );
    const userType = useSelector((state) => state.userType);

    if (isFavoritesPage || userType !== UserType.Applicant) {
        return null;
    }

    return (
        <BlacklistMenu
            vacancyId={vacancyId}
            employerId={employerId}
            isAnonymousVacancy={isAnonymousVacancy}
            render={() => <></>}
            isMagritte
        />
    );
};

export default translation(BlacklistButton);
