import useExperiment from 'src/hooks/useExperiment';
import useMagritte from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

export const useApplicantSearchQuestionExp = (
    vacancyShowQuestionInput = false,
    sendCheck = true
): { isQuestionExpB: boolean; isQuestionExpC: boolean; isQuestionAvailable: boolean } => {
    const userType = useSelector((state) => state.userType);
    const hasFinishedResumes = useSelector((state) => state.applicantInfo?.finished > 0);
    const isApplicant = userType === UserType.Applicant;
    const isMagritte = useMagritte();

    const isCheckQuestionExp = isApplicant && isMagritte && hasFinishedResumes;
    const isQuestionExpB = useExperiment('applicant_search_question_b', isCheckQuestionExp, sendCheck);
    const isQuestionExpC = useExperiment('applicant_search_question_c', isCheckQuestionExp, sendCheck);
    const isQuestionAvailable = vacancyShowQuestionInput && (isQuestionExpB || isQuestionExpC);

    return { isQuestionExpB, isQuestionExpC, isQuestionAvailable };
};
