import { Store } from 'redux';

import Analytics from '@hh.ru/analytics-js';

import { AppStore } from 'src/app/store';
import {
    resumeSearchSeenAnalyticsDeleteOldDataAction,
    resumeSearchSeenAnalyticsMarkAsSentAction,
} from 'src/models/resumeSearchSeenAnalytics';
import {
    vacancySearchSeenAnalyticsDeleteOldDataAction,
    vacancySearchSeenAnalyticsMarkAsSentAction,
} from 'src/models/vacancySearchSeenAnalytics';

enum SeenEvent {
    Vacancy = 'LastVacancySearchPositionSeen',
    Resume = 'LastResumeSearchPositionSeen',
}

const eventKeys = {
    vacancyId: 'vacancy_id',
    requestId: 'request_id',
    resumeHash: 'resume_hash',
    position: 'position',
};

let isVisibilityChangeListenerActive = false;
let storeInstance: Store<AppStore> | null = null;

export const sendSeenAnalytics = (shouldDeleteCollectedData = false): void => {
    const vacancySearchSeenAnalytics = storeInstance?.getState().vacancySearchSeenAnalytics ?? {};
    const resumeSearchSeenAnalytics = storeInstance?.getState().resumeSearchSeenAnalytics ?? {};
    if (
        Object.entries(vacancySearchSeenAnalytics).length === 0 &&
        Object.entries(resumeSearchSeenAnalytics).length === 0
    ) {
        return;
    }

    Object.entries(vacancySearchSeenAnalytics).forEach(([searchRid, lastSeenObj]) => {
        if (!lastSeenObj.wasSent) {
            Analytics.sendHHEvent(SeenEvent.Vacancy, {
                ...lastSeenObj.globalAnalyticsParams,
                [eventKeys.position]: lastSeenObj.lastSeenPosition,
                [eventKeys.vacancyId]: lastSeenObj.lastSeenId,
                [eventKeys.requestId]: searchRid,
            });
        }
    });

    Object.entries(resumeSearchSeenAnalytics).forEach(([searchRid, lastSeenObj]) => {
        if (!lastSeenObj.wasSent) {
            Analytics.sendHHEvent(SeenEvent.Resume, {
                ...lastSeenObj.globalAnalyticsParams,
                [eventKeys.position]: lastSeenObj.lastSeenPosition,
                [eventKeys.resumeHash]: lastSeenObj.lastSeenId,
                [eventKeys.requestId]: searchRid,
            });
        }
    });

    if (shouldDeleteCollectedData) {
        storeInstance?.dispatch(vacancySearchSeenAnalyticsDeleteOldDataAction());
        storeInstance?.dispatch(resumeSearchSeenAnalyticsDeleteOldDataAction());
    } else {
        storeInstance?.dispatch(vacancySearchSeenAnalyticsMarkAsSentAction());
        storeInstance?.dispatch(resumeSearchSeenAnalyticsMarkAsSentAction());
    }
};

const visibilityChangeListener = () => {
    if (document.visibilityState === 'hidden') {
        sendSeenAnalytics();
    }
};

export const addVisibilityChangeListener = (store: Store<AppStore>): void => {
    if (isVisibilityChangeListenerActive) {
        return;
    }

    storeInstance = store;
    document.addEventListener('visibilitychange', visibilityChangeListener);
    isVisibilityChangeListenerActive = true;
};
