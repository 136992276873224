import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    showError: 'vacancy.search.contacts.show.error',
};

const ShowContactsError: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.showError]}</>;

export default {
    Element: translation(ShowContactsError),
    kind: 'error',
    autoClose: true,
};
