import { updateUrl } from 'Modules/url';

import fetcher from 'src/utils/fetcher';

export enum AdvAnalyticsTypes {
    FavoriteButton = 'add_to_favorites_button',
    ContactsButton = 'show_contacts_button',
}

export const sendAdvSearchVacancyAnalytics = (params: {
    clickUrl?: string;
    isAdv?: boolean;
    analyticsType: AdvAnalyticsTypes;
}): void => {
    const { isAdv, clickUrl, analyticsType } = params;
    if (isAdv && clickUrl) {
        void fetcher.get(updateUrl(clickUrl, { clickType: analyticsType }));
    }
};
