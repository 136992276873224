import { FC, PropsWithChildren } from 'react';

import styles from './styles.less';

type Props = PropsWithChildren & {
    isAdv?: boolean;
};

const AdvMarkContainer: FC<Props> = ({ isAdv, children }) => {
    if (!isAdv) {
        return <>{children}</>;
    }

    return (
        <div className={styles.advMarkContainer}>
            <div className={styles.advMark}></div>
            {children}
        </div>
    );
};

export default AdvMarkContainer;
