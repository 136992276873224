import { ImgHTMLAttributes } from 'react';

export interface BrandGradient {
    colorList?: {
        color?: string;
        position?: number;
    }[];
    angle?: number;
}

export enum PictureType {
    Logo = 'logo',
    Picture = 'picture',
}

export interface PictureScalableAttribute {
    width?: number;
    height?: number;
    url?: string;
}

interface PictureScalable {
    default?: PictureScalableAttribute;
    xs?: PictureScalableAttribute;
}

export interface BrandSnippet {
    logo?: string;
    logoXs?: string;
    logoScalable?: PictureScalable;
    picture?: string;
    pictureXs?: string;
    pictureScalable?: PictureScalable;
    background?: {
        color?: string;
        gradient?: BrandGradient;
    };
}

export type PictureAttributes = ImgHTMLAttributes<HTMLImageElement>;

export interface BrandedSnippetDecoration {
    hasLoadingError: boolean;
    isLoading: boolean;
    hasAdditionalPicture: boolean;
    logo?: PictureAttributes;
    picture?: PictureAttributes;
    showViewForXs: boolean;
    backgroundStyle: {
        backgroundImage?: string;
        backgroundColor?: string;
    };
}
