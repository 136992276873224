import { useState } from 'react';

import { useChatik } from '@hh.ru/chatik-integration';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import { FormItem } from 'bloko/blocks/form';
import { ModalContent } from 'bloko/blocks/modal';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import CoverLetterForm from 'src/components/Applicant/Response/CoverLetter/Form';
import Source from 'src/components/VacancySearchItem/types/Source';
import translation from 'src/components/translation';

import styles from './resume-delivered.less';

const TrlKeys = {
    title: 'vacancy.responded.success.title',
    questionTitle: 'vacancy.responded.success.question.title',
    responseStats: 'vacancy.view.notify.response.stats',
    writeLtter: 'vacancy.response.letter.informer.writeButton',
    sentLetterFrom: 'vacancy.response.letter.informer.sent.vacancy',
    chatDescription: 'vacancy.view.notify.response.chat',
    questionChatDescription: 'vacancy.view.notify.questionResponse.chat',
    goToChat: 'vacancy.view.response.openChat',
};

interface ResponseDataProps {
    readPercent: number;
    topicId: string;
    vacancyId: number;
    hasResponseLetter: boolean;
    chatId?: number;
}

interface ResumeDeliveredFormProps {
    responseData: ResponseDataProps;
    isLetterTextEmpty: boolean;
    letterMaxLength: number;
    vacancySource: Source;
    isQuestionResponse?: boolean;
    onClose?: () => void;
}

const ResumeDeliveredForm: TranslatedComponent<ResumeDeliveredFormProps> = ({
    responseData,
    isLetterTextEmpty,
    letterMaxLength,
    vacancySource,
    isQuestionResponse,
    onClose,
    trls,
}) => {
    const [showLetterForm, setShowLetterForm] = useState(false);
    const [isLetterFormSuccessSend, setLetterFormSuccessSend] = useState(false);

    const { readPercent, topicId, hasResponseLetter, chatId } = responseData;

    const { openChatik } = useChatik();

    const renderCoverLetterForm = () =>
        isLetterFormSuccessSend ? (
            <Text size={TextSize.Large} importance={TextImportance.Secondary} italic>
                {trls[TrlKeys.sentLetterFrom]}
            </Text>
        ) : (
            <CoverLetterForm
                setSuccessSend={setLetterFormSuccessSend}
                letterMaxLength={letterMaxLength}
                topicId={Number(topicId)}
                submitButtonStretched
            />
        );

    const getDescription = () => {
        if (readPercent > 0) {
            return format(trls[TrlKeys.responseStats], {
                '{0}': readPercent,
            });
        }

        return isQuestionResponse ? trls[TrlKeys.questionChatDescription] : trls[TrlKeys.chatDescription];
    };
    return (
        <>
            <ModalContent>
                <div className={styles.successInfo}>
                    <VSpacing base={6} />
                    <div className={styles.successIcon} />
                    <VSpacing base={5} />
                    <Text size={TextSize.ExtraLarge} strong>
                        {trls[isQuestionResponse ? TrlKeys.questionTitle : TrlKeys.title]}
                    </Text>
                    <VSpacing base={2} />
                    <Text size={TextSize.Large}>{getDescription()}</Text>
                    <VSpacing base={5} />
                    {isLetterTextEmpty && !hasResponseLetter && (
                        <FormItem>
                            {showLetterForm ? (
                                renderCoverLetterForm()
                            ) : (
                                <Button kind={ButtonKind.Primary} stretched onClick={() => setShowLetterForm(true)}>
                                    {trls[TrlKeys.writeLtter]}
                                </Button>
                            )}
                        </FormItem>
                    )}
                    {topicId !== 'SPAM' && (
                        <Button
                            kind={ButtonKind.Primary}
                            appearance={ButtonAppearance.Outlined}
                            stretched
                            onClick={() => {
                                openChatik?.({ chatId, hhtmFromLabel: vacancySource });
                                onClose?.();
                            }}
                        >
                            {trls[TrlKeys.goToChat]}
                        </Button>
                    )}
                </div>
            </ModalContent>
        </>
    );
};

export default translation(ResumeDeliveredForm);
