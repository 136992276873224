import { FC } from 'react';

import { VSpacing, HSpacing, Text } from '@hh.ru/magritte-ui';

import { Address } from 'src/components/Employer/VacanciesGroupsByRegion/VacancyGroupsTypes';
import VacancyMetroList from 'src/components/VacancySearchItem/MetroList';
import { useCardContainerClassNames } from 'src/components/VacancySearchItem/hooks/useCardContainerClassNames';

type VacancyAddressProps = {
    area?: {
        '@id': number;
        name: string;
    };
    address?: Address;
};

const VacancyAddress: FC<VacancyAddressProps> = ({ area, address }) => {
    const metroList = address?.metroStations?.metro?.filter((metro) => metro['@cityId'] === area?.['@id']) ?? [];
    const { wideCardContainerClassName, narrowCardContainerClassName } = useCardContainerClassNames();

    const areaNameElement = area && Boolean(area.name) && (
        <Text typography="label-3-regular" Element="span" data-qa="vacancy-serp__vacancy-address">
            {area.name}
        </Text>
    );

    return (
        <>
            <div className={narrowCardContainerClassName}>
                <VSpacing default={12} />
                {areaNameElement}
                {metroList.length > 0 && (
                    <>
                        <VSpacing default={4} />
                        <VacancyMetroList metroList={metroList} />
                    </>
                )}
            </div>

            <div className={wideCardContainerClassName}>
                <VSpacing default={8} />
                {areaNameElement}
                {metroList.length > 0 && (
                    <>
                        <HSpacing default={12} />
                        <VacancyMetroList metroList={metroList} />
                    </>
                )}
            </div>
        </>
    );
};

export default VacancyAddress;
