import { FC } from 'react';
import classnames from 'classnames';

import { Text, VSpacingContainer, VSpacing } from '@hh.ru/magritte-ui';

import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import { useSelector } from 'src/hooks/useSelector';
import { VacancySnippet } from 'src/models/vacancySearch/vacancySearchItem.types';

import styles from './styles.less';

interface DescriptionProps {
    snippet?: VacancySnippet;
    highlightedShort?: boolean;
}

const Description: FC<DescriptionProps> = ({ snippet, highlightedShort }) => {
    const isMapVacancySearch = useSelector((state) => state.isMapVacancySearch);

    if (!snippet?.resp && !snippet?.req) {
        return null;
    }

    return (
        <div className={classnames('g-user-content', styles.description)}>
            <VSpacing default={24} gteS={isMapVacancySearch ? 24 : 16} />
            <VSpacingContainer default={8}>
                {snippet.resp && (
                    <Text typography="paragraph-3-regular" data-qa="vacancy-serp__vacancy_snippet_responsibility">
                        <AsyncHighlighter short={highlightedShort}>{snippet.resp}</AsyncHighlighter>
                    </Text>
                )}
                {snippet.req && (
                    <Text typography="paragraph-3-regular" data-qa="vacancy-serp__vacancy_snippet_requirement">
                        <AsyncHighlighter short={highlightedShort}>{snippet.req}</AsyncHighlighter>
                    </Text>
                )}
            </VSpacingContainer>
        </div>
    );
};

export default Description;
