import { Tag } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { ExperienceOption } from 'src/models/search/searchCriteria.types';
import { ScheduleCamelCase } from 'src/utils/constants/schedule';

const TrlKeys = {
    experience: {
        title: 'vacancy.experience.title',
        [ExperienceOption.NoExperience]: 'vacancy.experience.noExperience',
        [ExperienceOption.Between1And3]: 'vacancy.experience.between1And3',
        [ExperienceOption.Between3And6]: 'vacancy.experience.between3And6',
        [ExperienceOption.MoreThan6]: 'vacancy.experience.moreThan6',
    },
    schedule: {
        [ScheduleCamelCase.Remote]: 'label.workSchedule.canWorkRemotely',
        [ScheduleCamelCase.FlyInFlyOut]: 'label.workSchedule.flyInFlyOut',
    },
};

interface ExperienceAndScheduleProps {
    workExperience: ExperienceOption;
    workSchedule: ScheduleCamelCase;
}

const ExperienceAndSchedule: TranslatedComponent<ExperienceAndScheduleProps> = ({
    workExperience,
    workSchedule,
    trls,
}) => {
    const isScheduleAllowedLabelShown =
        workSchedule === ScheduleCamelCase.Remote || workSchedule === ScheduleCamelCase.FlyInFlyOut;

    if (!workExperience && !isScheduleAllowedLabelShown) {
        return null;
    }

    const experienceText =
        workExperience === ExperienceOption.NoExperience
            ? trls[TrlKeys.experience[workExperience]]
            : format(trls[TrlKeys.experience.title], { '{0}': trls[TrlKeys.experience[workExperience]] });

    return (
        <>
            <Tag>
                <span data-qa={`vacancy-serp__vacancy-work-experience-${workExperience}`}>{experienceText}</span>
            </Tag>
            {isScheduleAllowedLabelShown && (
                <Tag>
                    <span data-qa={`vacancy-label-work-schedule-${workSchedule}`}>
                        {trls[TrlKeys.schedule[workSchedule]]}
                    </span>
                </Tag>
            )}
        </>
    );
};

export default translation(ExperienceAndSchedule);
