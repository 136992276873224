import { FC, ReactElement } from 'react';

import { Avatar } from '@hh.ru/magritte-ui';

interface BadgeContainerProps {
    icon: ReactElement;
    size: 'small' | 'medium';
}

const avatarSizeMap = {
    small: 24,
    medium: 48,
} as const;

const BadgeContainer: FC<BadgeContainerProps> = ({ icon, size }) => (
    <Avatar mode="icon" size={avatarSizeMap[size]} aria-label="hr brand icon" shapeCircle style="accent" icon={icon} />
);

export default BadgeContainer;
