import { FC } from 'react';

import { LabelFlameFilledSize16 } from '@hh.ru/magritte-ui/product';
import { H2Section } from 'bloko/blocks/header';

import LinkToVacancy, { LinkToVacancyProps } from 'src/components/VacancySearchItem/LinkToVacancy';

import styles from './styles.less';

type VacancyNameProps = {
    name: string;
    vacancyLinkProps: LinkToVacancyProps;
    shouldShowFireLabel: boolean;
};

const VacancyName: FC<VacancyNameProps> = ({ name, vacancyLinkProps, shouldShowFireLabel }) => {
    return (
        <H2Section>
            <LinkToVacancy {...vacancyLinkProps}>
                {name}
                {shouldShowFireLabel && (
                    <span className={styles.premiumContainer}>
                        <LabelFlameFilledSize16 initial="negative" />
                    </span>
                )}
            </LinkToVacancy>
        </H2Section>
    );
};

export default VacancyName;
